.banner {
  object-fit: contain;
  height: 500px;
  width: 100%;
  color: white;
}

@media (max-width: 480px) {
  .banner {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 150px;
  }
}
